import RegentLogo from '../media/logo/logo_rvb_ecusson_bleu.png';
import ProfileIcon from '../media/icon/schoolhub_profile_icon.png';
import AppIcon from '../media/icon/schoolhub_app_icon.png';
import { useState, useRef, useEffect, useContext } from "react";
import LoginWindow from './LoginWindow';
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ACCOUNT_BY_ID } from "../queries/account_queries";
import {jwtDecode} from "jwt-decode";

const Menu: React.FC = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [attemptedProfileAccess, setAttemptedProfileAccess] = useState(false);
    const loginWindowRef = useRef<HTMLDivElement>(null);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    // Lazy query to get account information by ID
    const [getAccountById, { data, loading, error }] = useLazyQuery(GET_ACCOUNT_BY_ID, {
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (isLoginModalOpen) {
            document.body.addEventListener("mousedown", handlePageClick);
        } else {
            document.body.removeEventListener("mousedown", handlePageClick);
        }
        return () => {
            document.body.removeEventListener("mousedown", handlePageClick);
        };
    }, [isLoginModalOpen]);

    const handlePageClick = (event: MouseEvent) => {
        if (
            isLoginModalOpen &&
            loginWindowRef.current &&
            !loginWindowRef.current.contains(event.target as Node)
        ) {
            closeLoginModal();
        }
    };

    const openLoginModal = () => {
        setIsLoginModalOpen(true);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
        setAttemptedProfileAccess(false);
    };

    const handleLoginSuccess = () => {
        setIsLoginModalOpen(false);
        setAttemptedProfileAccess(false);
        navigate("/modules");
    };

    const handleRegisterLinkClick = () => {
        setIsLoginModalOpen(false);
        setAttemptedProfileAccess(false);
        navigate("/register");
    };

    const handleProfileClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAttemptedProfileAccess(true); // Track that user attempted to access profile
        if (token) {
            getAccountById({
                variables: { accountId: extractIdFromToken(token) }
            }).then(response => {
                // Navigate only if valid account data is found
                if (response.data?.getAccountById) {
                    navigate("/profile");
                } else {
                    openLoginModal();
                }
            });
        } else {
            openLoginModal();
        }
    };

    const extractIdFromToken = (token: string): string | null => {
        try {
            const decodedToken = jwtDecode<{ id: string }>(token);
            return decodedToken.id;
        } catch {
            return null;
        }
    };

    return (
        <div className="bg-regentyolk h-20 flex justify-between items-center px-4">
            {/* Left of menu */}
            <section className="flex items-center cursor-pointer" onClick={() => navigate("/")}>
                <img src={RegentLogo} alt="School Hub Logo" className="w-16" />
                <div className="ml-4">
                    <p className="font-bold text-3xl text-white">School Hub</p>
                    <p className="font-bold text-sm text-white">A Régent International School Application</p>
                </div>
            </section>

            {/* Right of menu */}
            <section className="relative flex justify-end space-x-4">
                <img src={AppIcon} alt="Applications Icon" className="w-8 cursor-pointer" onClick={() => navigate("/modules")} />
                <img
                    src={ProfileIcon}
                    alt="Profile Icon"
                    className="w-8 cursor-pointer"
                    onClick={handleProfileClick}
                />

                {/* Login Modal */}
                {isLoginModalOpen && (
                    <div
                        ref={loginWindowRef}
                        className="absolute top-full right-0 mt-2 bg-white z-50 w-96"
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        <LoginWindow
                            onLoginSuccess={handleLoginSuccess}
                            onRegisterLinkClick={handleRegisterLinkClick}
                        />
                    </div>
                )}
            </section>
        </div>
    );
};

export default Menu;
