import React from 'react';

interface CheckboxProps {
    label: string;
    onChange: (value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, onChange }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <label className="flex items-center space-x-3">
            <input type="checkbox" className="form-checkbox" onChange={handleChange} />
            <span className="text-gray-900 font-normal">{label}</span>
        </label>
    );
}

export default Checkbox;