import { useQuery, DocumentNode } from "@apollo/client";

export const useGetList = (
    query: DocumentNode,
    propertyName: string,
    params: string[],
    variables: object = {},
) => {
    const { loading, error, data } = useQuery(query, { variables });

    if (loading) return { data: [], loading, error };
    if (error) return { data: [], loading, error };

    const processedData = data[propertyName].map((item: any) => {
        // If only one param is passed, return a string
        if (params.length === 1) {
            return item[params[0]];
        }

        // If multiple params are passed, return an object
        const obj: { [key: string]: any } = {};
        params.forEach((param) => {
            obj[param] = item[param];
        });
        return obj;
    });

    return { data: processedData, loading, error };
};
