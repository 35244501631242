import React from "react";
import ResetPasswordCard from "../components/ResetPasswordCard";
import {useParams} from "react-router-dom";
import PrimaryButton from "../components/sub-components/PrimaryButton";


type ResetPasswordProps = {
    onLogOut: () => void;
};


const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
    const { resetCode } = useParams();

    return (
        <>
            <ResetPasswordCard passwordForgotten={true} resetCode={resetCode}/>
        </>
    );
};

export default ResetPassword;
