import React, {useContext, useState} from 'react';
import TextInput from "./sub-components/TextInput";
import PrimaryButton from "./sub-components/PrimaryButton";
import {
    GET_ACCOUNT_BY_EMAIL,
    GET_ACCOUNT_BY_PASSWORD_RESET_CODE,
    RESET_PASSWORD,
    SEND_RESET_URL
} from "../queries/account_queries";
import {RESET_PASSWORD_WITH_CODE} from "../queries/account_queries";
import {LOGIN} from "../queries/account_queries";
import {useLazyQuery, useMutation} from "@apollo/client";
import {AuthContext} from "../AuthProvider";
import {jwtDecode} from "jwt-decode";

type DecodedToken = {
    email: string;
    fullname: string;
    id: string;
}

type ResetPasswordCardProps = {
    passwordForgotten: boolean;
    resetCode?: string;
};

const ResetPasswordCard: React.FC<ResetPasswordCardProps> = ({
                                                                 passwordForgotten,
                                                                 resetCode
                                                             }) => {
    const auth = useContext(AuthContext);
    let id = "";
    let email = "";

    if (auth.token) {
        const decodedToken = jwtDecode<DecodedToken>(auth.token);
        id = decodedToken.id;
        email = decodedToken.email;
    }

    const [currentPassword, setCurrentPassword] = useState("");
    const [insertedEmail, setInsertedEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [message, setMessage] = useState<String>("")
    const [messageColor, setMessageColor] = useState<String>("text-black")

    const [resetPassword] = useMutation(RESET_PASSWORD);
    const [resetPasswordWithCode] = useMutation(RESET_PASSWORD_WITH_CODE);
    const [sendResetUrl] = useMutation(SEND_RESET_URL);
    const [getAccountByPasswordResetCode] = useLazyQuery(GET_ACCOUNT_BY_PASSWORD_RESET_CODE);
    const [login, {data, called, loading}] = useLazyQuery(LOGIN, {fetchPolicy: "network-only"});
    const [getAccountByEmail, {data: accountData}] = useLazyQuery(GET_ACCOUNT_BY_EMAIL);

    const resetPasswordWhileLoggedIn = async () => {
        setMessageColor("text-red-500");
        const loginRes = await login(
            {
                variables: {
                    email,
                    password: currentPassword
                }
            });

        console.log("Login function was called");

        if (loginRes.data.login == "NOK") {
            setMessage("Incorrect password provided.");
            return;
        }

        if (called && !loading && data) {
            if (data.login) {
                resetPassword({
                    variables: {
                        newPassword,
                        accountId: id
                    }
                }).then((result) => {
                    if (result.data?.resetPassword?.message) {
                        setMessage("Password reset successful");
                        setCurrentPassword("");
                        setNewPassword("");
                        setConfirmNewPassword("");
                    }
                }).catch(error => {
                    // handle the error here
                    console.error(error);
                });
            } else {
                setMessage("Incorrect password provided");
            }
        }
    }

    const handleSendResetUrlClick = async () => {
        setMessageColor("text-black");
        setMessage("An email has  been sent to the above email address.");
        const resetRes = await sendResetUrl(
            {
                variables: {
                    accountEmail: insertedEmail
                }
            });
    }

    const resetPasswordWithResetCode = async () => {
        const accountRes = await getAccountByPasswordResetCode(
            {
                variables: {
                    resetCode: resetCode
                }
            });

        resetPasswordWithCode({
            variables: {
                newPassword,
                accountId: accountRes.data.getAccountByPasswordResetCode.accountId,
                resetCode
            }
        }).then((result) => {
            if (result.data?.resetPasswordWithCode?.message) {
                setMessage("Password reset successful");
                setNewPassword("");
                setConfirmNewPassword("");
                setInsertedEmail("");
            }
        }).catch(error => {
            // handle the error here
            console.error(error);
        });
    }

    const handleResetPassword = async () => {
        setMessage("");
        setMessageColor("text-red-500")

        if (currentPassword == newPassword) {
            setMessage("The new password is the same as the current password.");
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setMessage("Passwords do not match");
            return;
        }

        if (newPassword.length < 8) {
            setMessage("Password must be at least 8 characters long.");
            return;
        }

        if (passwordForgotten) {
            await resetPasswordWithResetCode()
        } else {
            await resetPasswordWhileLoggedIn()
        }
    };

    return (
        <div className="border p-8 rounded-lg shadow">
            <div className="flex justify-between items-center">
                <h2 className="font-bold text-lg">Reset Password</h2>
            </div>
            <div className="mt-4">
                {!passwordForgotten && (
                    <TextInput
                        label="Current Password"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(event) => setCurrentPassword(event.target.value)}
                        mandatory={true}
                        secret={true}
                        width="nano"
                    />
                )}
                {passwordForgotten && !resetCode && (
                    <div className="mb-8">
                        <div className="mb-4">
                            <TextInput
                                label="Account Email"
                                placeholder="example@gmail.com"
                                value={insertedEmail}
                                onChange={(event) =>
                                    setInsertedEmail(event.target.value)}
                                mandatory={true}
                                width="nano"
                            />
                        </div>
                        <PrimaryButton
                            label="Request password reset URL"
                            action={handleSendResetUrlClick}
                            width="nano"
                        />
                    </div>

                )}
                {((resetCode && passwordForgotten) || (!resetCode && !passwordForgotten)) && (
                    <>
                        <TextInput
                            label="New Password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(event) =>
                                setNewPassword(event.target.value)}
                            mandatory={true}
                            secret={true}
                            width="nano"
                        />
                        <TextInput
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={(event) =>
                                setConfirmNewPassword(event.target.value)}
                            mandatory={true}
                            secret={true}
                            width="nano"
                        />
                    </>
                )}

            </div>
            <p className={"message mb-3 " +
                (message === "Password reset successful" ? "text-green-500" : messageColor)}>{message}</p>
            {((resetCode && passwordForgotten) || (!resetCode && !passwordForgotten)) && (
                <div className="mt-8">
                    <PrimaryButton
                        label="Reset Password"
                        action={handleResetPassword}
                        width="nano"
                    />
                </div>
            )}
        </div>
    );
};

export default ResetPasswordCard;