import React from 'react';

interface PrimaryButtonProps {
    label: string;
    action: () => void;
    width: "half" | "full" | "nano";
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ label, action, width }) => {
    const determineWidth = () => {
        switch (width) {
            case "half":
                return "w-1/2";
            case "full":
                return "w-full"
            case "nano":
                return "w-1/8";
            default:
                return "w-full";
        }
    };

    return (
        <button
            onClick={action}
            className={`py-2 px-4 bg-regentyolk text-white font-semibold rounded-lg shadow-md hover:bg-regentblue focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ${determineWidth()}`}
        >
            {label}
        </button>
    );
}

export default PrimaryButton;