import { gql } from "@apollo/client";

export const GET_ACCESS_BY_ACCOUNT_ID = gql`
    query GetAccessByAccountId($accountId: String!) {
        getAccessByAccountId(accountId: $accountId) {
            accessCode
        }
    }
`;

