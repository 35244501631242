import { gql } from "@apollo/client";

export const ADD_ACCOUNT = gql`
mutation AddAccount($phone: String!, $phoneExtension: String!, $email: String!, $firstname: String!, $lastname: String!, $password: String!,
$billingAddressId: UUID!, $domicileAddressId: UUID!, $roleId: UUID!) {
  addAccount(
    accountInput: {phone: $phone, phoneExtension: $phoneExtension, email: $email, firstname: $firstname, lastname: $lastname, password: $password, 
    billingAddressId: $billingAddressId, domicileAddressId: $domicileAddressId, roleId: $roleId}
  ) {
    message
    errorCode
    account {
      accountId
    }
  }
}
`;

export const LOGIN = gql`
    query Login($email: String!, $password: String!) {
  login(email: $email, password: $password)
    }
    `;

export const GET_ACCOUNT_BY_EMAIL = gql`
query GetAccountByEmail($email: String!) {
  getAccountByEmail(email: $email) {
    accountId
  }
}
`;

export const GET_ACCOUNT_BY_ID = gql`
    query GetAccountById($accountId: String!) {
  getAccountById(accountId: $accountId) {
    domicileAddress {
      addressId
      streetLine1
      streetLine2
      zipCode
      country {
        countryId
        countryName
      }
      city
    }
    billingAddress {
      addressId
      streetLine1
      streetLine2
      zipCode
      country {
        countryId
        countryName
      }
      city
    }
    firstname
    lastname
    phone
    email
  }
}
`

export const RESET_PASSWORD = gql`
    mutation ResetPassword($newPassword: String!, $accountId: String!) {
  resetPassword(newPassword: $newPassword, accountId: $accountId) {
  message
  }
}
`

export const RESET_PASSWORD_WITH_CODE = gql`
    mutation ResetPasswordWithCode($newPassword: String!, $accountId: String!, $resetCode: String!) {
  resetPasswordWithCode(newPassword: $newPassword, accountId: $accountId, resetCode: $resetCode) {
  message
  }
}
`

export const EDIT_ACCOUNT = gql`
  mutation EditAccount($accountId: String!, $accountInput: AccountInput!) {
    editAccount(accountId: $accountId, accountInput: $accountInput) {
      message
    }
  }
`;

export const SEND_RESET_URL = gql`
mutation SendResetUrl($accountEmail: String!) {
  sendResetUrl(accountEmail: $accountEmail)
}
`;

export const GET_ACCOUNT_BY_PASSWORD_RESET_CODE = gql`
    query GetAccountByPasswordResetCode($resetCode: String!) {
      getAccountByPasswordResetCode(resetCode: $resetCode) {
        accountId
      }
    }
`;

export const EDIT_ADDRESS = gql`
  mutation EditAddress($addressId: String!, $addressInput: AddressInput!) {
    editAddress(addressId: $addressId, addressInput: $addressInput) {
      message
    }
  }
`;