import React, { useEffect, useState, useCallback } from "react";
import TextInput from "./sub-components/TextInput";
import Dropdown from "./sub-components/Dropdown";
import { GET_COUNTRIES } from '../queries/country_queries';
import { GET_REGIONS_BY_COUNTRY_ID } from "../queries/region_queries";
import { useGetList } from "../helpers/useGetList";
import { useLazyQuery } from "@apollo/client";

type AddressFormProps = {
    addressType: string;
    onAddressChange: (addressType: string, values: any) => void;
};

const AddressForm: React.FC<AddressFormProps> = ({ addressType, onAddressChange }) => {
    // Declare state for form fields
    const [companyName, setCompanyName] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
    const [regionOptions, setRegionOptions] = useState([]);

    // Fetch countries once
    const { data: fetchedCountries } = useGetList(
        GET_COUNTRIES,
        "getCountries",
        ["countryName", "countryId"]
    );

    // Initialize countryOptions directly based on fetched data
    const countryOptions = fetchedCountries
        ? fetchedCountries.map((country: any) => ({
            id: country.countryId,
            value: country.countryName,
        }))
        : [];

    // Lazy query for regions
    const [fetchRegions, { data: fetchedRegions }] = useLazyQuery(GET_REGIONS_BY_COUNTRY_ID);

    // Memoized updateAddressChange function to avoid extra re-renders
    const updateAddressChange = useCallback(
        (newData: Record<string, any>) => {
            onAddressChange(addressType, newData);
        },
        [onAddressChange, addressType]
    );

    useEffect(() => {
        if (fetchedRegions) {
            const formattedRegions = fetchedRegions.getRegionsByCountryId.map((region: any) => ({
                id: region.regionId,
                value: region.regionName,
            }));
            setRegionOptions(formattedRegions);
        }
    }, [fetchedRegions]);


    // Handle country change and region fetch
    const handleCountryChange = useCallback(
        (event: { name: string; value: string; id: string | number | undefined }) => {
            const newCountry = event.value;
            const newCountryId = event.id?.toString();

            if (country !== newCountry) {
                setCountry(newCountry);
                setSelectedCountryId(newCountryId);
                setRegion('');
                setRegionOptions([]);
            }

            if (newCountryId) {
                void fetchRegions({ variables: { countryId: newCountryId } });
            }

            updateAddressChange({ companyName, street1, street2, city, region: '', zip, country: newCountry });
        },
        [fetchRegions, updateAddressChange, companyName, street1, street2, city, zip, country]
    );


    // Handle region change
    const handleRegionChange = useCallback(
        (event: { name: string; value: string; id: string | number | undefined }) => {
            const newRegion = event.value;
            if (region !== newRegion) {
                setRegion(newRegion);
                updateAddressChange({ companyName, street1, street2, city, region: newRegion, zip, country });
            }
        },
        [updateAddressChange, companyName, street1, street2, city, zip, country, region]
    );

    // Handle text input changes
    const handleInputChange = useCallback(
        (setter: React.Dispatch<React.SetStateAction<string>>, key: string, value: string) => {
            setter(value);
            updateAddressChange({
                companyName,
                street1,
                street2,
                city,
                region,
                zip,
                country,
                [key]: value,
            });
        },
        [updateAddressChange, companyName, street1, street2, city, region, zip, country]
    );

    return (
        <>
            <TextInput
                placeholder={"My Company SA"}
                label={"Company Name"}
                mandatory={false}
                width={"full"}
                onChange={(e) => handleInputChange(setCompanyName, 'companyName', e.target.value)}
            />
            <TextInput
                placeholder={"Main Street 123A"}
                label={"Street Address - Line 1"}
                mandatory={true}
                width={"full"}
                onChange={(e) => handleInputChange(setStreet1, 'street1', e.target.value)}
            />
            <TextInput
                placeholder={"Building 12B"}
                label={"Street Address - Line 2"}
                mandatory={false}
                width={"full"}
                onChange={(e) => handleInputChange(setStreet2, 'street2', e.target.value)}
            />
            <div className="flex">
                <TextInput
                    placeholder={"Crans-Montana"}
                    label={"City"}
                    mandatory={true}
                    width={"half"}
                    onChange={(e) => handleInputChange(setCity, 'city', e.target.value)}
                />
                <div className="ml-12 mt-3">
                    <Dropdown
                        name="country"
                        label={"Country"}
                        options={countryOptions}
                        mandatory={true}
                        width={"half"}
                        onChange={handleCountryChange}
                        value={country}
                    />
                </div>
            </div>
            <div className="flex">
                <TextInput
                    placeholder={"3963"}
                    label={"ZIP/Postal Code"}
                    mandatory={true}
                    width={"half"}
                    onChange={(e) => handleInputChange(setZip, 'zip', e.target.value)}
                />
                <div className="ml-12 mt-3">
                    <Dropdown
                        name="region"
                        label={"Region"}
                        options={regionOptions}
                        mandatory={true}
                        width={"half"}
                        onChange={handleRegionChange}
                        value={region}
                        active={regionOptions.length > 0} // Activate dropdown only when options are available
                    />
                </div>
            </div>
        </>
    );
};

export default AddressForm;
