import React from 'react';
import { Circles } from 'react-loader-spinner';

type LoaderProps = {
    text?: string;
};

const Loader: React.FC<LoaderProps> = ({ text }) => {
    return (
        <div className="flex flex-col justify-center items-center w-full h-full bg-regentblue opacity-50">
            <Circles
                height="80"
                width="80"
                color="#FFFFFF"
                ariaLabel="circles-loading"
                visible={true}
            />
            <p className="text-white mt-4">{text}</p>
        </div>
    );
};

export default Loader;
