import React, { useContext, useEffect, useState } from 'react';
import TextInput from "./sub-components/TextInput";
import CrayonIcon from "../media/icon/schoolhub_crayon_icon.png";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { EDIT_ACCOUNT, EDIT_ADDRESS } from "../queries/account_queries";
import { AuthContext } from "../AuthProvider";
import {jwtDecode} from "jwt-decode";
import Dropdown from "./sub-components/Dropdown";
import { GET_COUNTRIES, GET_COUNTRY_ID } from '../queries/country_queries';

type InfoField = {
    title: string;
    value: string;
};

type CardProps = {
    title: string;
    infoFields: InfoField[];
    refetchAccount: () => void;
    accountInfo: any;
};

type DecodedToken = {
    email: string;
    fullname: string;
    id: string;
};

const InformationCard: React.FC<CardProps> = ({ title, infoFields, refetchAccount, accountInfo }) => {
    const auth = useContext(AuthContext);
    let id = "";

    if (auth.token) {
        const decodedToken = jwtDecode<DecodedToken>(auth.token);
        id = decodedToken.id;
    }

    const [editMode, setEditMode] = useState(false);
    const [editInfoFields, setEditInfoFields] = useState<InfoField[]>(JSON.parse(JSON.stringify(infoFields)));
    const [initialState, setInitialState] = useState<InfoField[]>(JSON.parse(JSON.stringify(infoFields)));
    const [editAccount] = useMutation(EDIT_ACCOUNT);
    const [country, setCountry] = useState('');
    const [editAddress] = useMutation(EDIT_ADDRESS);
    const { loading, error, data } = useQuery(GET_COUNTRIES);
    const [getCountryId] = useLazyQuery(GET_COUNTRY_ID);
    const countries = data?.getCountries.map((country: any) => ({ id: country.id, value: country.countryName })) || [];

    const titleToKeyMap: { [title: string]: string } = {
        'Email address': 'email',
        'Phone number': 'phone',
        'First name': 'firstname',
        'Last name': 'lastname',
        'Address Line 1': 'streetLine1',
        'Address Line 2': 'streetLine2',
        'ZIP': 'zipCode',
        'City': 'city',
        'Country': 'countryName',
    };

    useEffect(() => {
        // Sets the initial state when infoFields prop changes
        setInitialState(infoFields);
    }, [infoFields]);

    const handleChangeText = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newFields = [...editInfoFields];
        newFields[index].value = event.target.value;
        setEditInfoFields(newFields);
    };

    const handleEditCancel = () => {
        setEditInfoFields(initialState);
        setEditMode(false);
    };

    const toggleEditMode = () => {
        setEditMode((prevMode) => !prevMode);
    };

    const handleCountryChange = (value: { name: string; value: string; id: string | number | undefined }) => {
        const index = editInfoFields.findIndex(field => field.title === 'Country');
        if (index !== -1) {
            const newFields = [...editInfoFields];
            newFields[index].value = value.value;
            setEditInfoFields(newFields);
            setCountry(value.value);
        }
    };

    const handleEditSave = async () => {
        let accountInput: { [key: string]: any } = {};
        let addressInput: { [key: string]: any } = {};
        let updatedAddress = false;

        const edits = editInfoFields.map(async (field, index) => {
            let key = titleToKeyMap[field.title] || field.title;

            if (field.value !== initialState[index].value) {
                if (key === 'countryName') {
                    const result = await getCountryId({ variables: { countryName: field.value } });
                    addressInput["countryId"] = result.data.getCountryIdByName;
                    updatedAddress = true;
                } else if (key in accountInfo?.domicileAddress) {
                    addressInput[key] = field.value;
                    updatedAddress = true;
                } else {
                    accountInput[key] = field.value;
                }
            }
        });

        await Promise.all(edits);

        if (Object.keys(accountInput).length > 0) {
            await editAccount({
                variables: {
                    accountId: id,
                    accountInput,
                },
            });
        }

        if (updatedAddress) {
            await editAddress({
                variables: {
                    addressId: accountInfo.domicileAddress.addressId,
                    addressInput,
                },
            });
        }

        refetchAccount();
        setEditMode(false);
    };

    const renderInfo = () => editInfoFields.map((field, index) =>
        <div key={index} className="flex items-center justify-between my-2">
            <div className="text-gray-500 mr-4">{field.title}</div>
            {editMode ? (
                field.title === 'Country'
                    ?
                    <Dropdown
                        name="country"
                        options={countries}
                        mandatory={true}
                        width="nano"
                        onChange={handleCountryChange}
                        value={field.value}
                    />
                    :
                    <TextInput
                        label=""
                        placeholder={field.value}
                        value={field.value}
                        onChange={(event) => handleChangeText(index, event)}
                        mandatory={false}
                        width="nano"
                    />
            ) : (
                <div className="font-bold">{field.value}</div>
            )}
        </div>
    );

    return (
        <div className="border p-8 rounded-lg shadow max-w-lg mx-auto">
            <div className="flex justify-between items-center">
                <h2 className="font-bold text-lg">{title}</h2>
                <img
                    src={CrayonIcon}
                    alt="Edit Profile Information Section"
                    className="w-6 cursor-pointer"
                    onClick={toggleEditMode}
                />
            </div>
            <div className="mt-4">
                {renderInfo()}
            </div>
            {editMode &&
                <div className="flex justify-end mt-4">
                    <button
                        className="border-2 border-gray-200 rounded-md px-2 py-1 mr-2"
                        onClick={handleEditCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="border-2 border-blue-500 bg-blue-500 text-white rounded-md px-2 py-1"
                        onClick={handleEditSave}
                    >
                        Save
                    </button>
                </div>
            }
        </div>
    );
};

export default InformationCard;
