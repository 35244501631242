import {Route, Routes, useNavigate} from "react-router-dom";
import UseScrollToTop from "../helpers/useScrollToTop";
import Home from "../pages/Home";
import React, {useContext} from "react";
import ProfilePage from "../pages/ProfilePage";
import CreateAccount from "../pages/CreateAccount";
import ModuleList from "../pages/ModuleList";
import {AuthContext} from "../AuthProvider";
import ResetPassword from "../pages/ResetPassword";



const Body: React.FC = () => {
    const navigate = useNavigate();

    const auth = useContext(AuthContext);

    const handleRegistrationSuccess = (redirectUrl: string) => {
        if (redirectUrl.startsWith("http")) {
            // External URL, use window.location.href
            window.location.href = redirectUrl;
        } else {
            // Internal URL, use react-router's navigate
            navigate(redirectUrl);
        }
    };


    const handleLogOut = () => {
        const isProduction = process.env.REACT_APP_ENV === 'prod';
        auth.setToken("");

        let cookieString = "schoolhubToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

        if (isProduction) {
            // Add domain only in production
            cookieString += " domain=schoolhub.regentschool.ch;";
        }

        // Clear the cookie
        document.cookie = cookieString;

        // Redirect to the base URL
        window.location.href = process.env.REACT_APP_BASE_URL || "schoolhub.regentschool.ch";
    }

    return (
        <div className="min-h-screen">
            <UseScrollToTop />
            <Routes>
                <Route path="/" element={<ModuleList />} />
                <Route path="/register" element={<CreateAccount onRegisterSuccess={handleRegistrationSuccess}/>} />
                <Route path="/modules" element={<ModuleList />} />
                <Route path="/profile" element={<ProfilePage onLogOut={handleLogOut}/>} />
                <Route path="/reset-password/:resetCode" element={<ResetPassword onLogOut={handleLogOut}/>} />
                <Route path="/reset-password" element={<ResetPassword onLogOut={handleLogOut}/>} />
            </Routes>
        </div>
    );
};

export default Body;
