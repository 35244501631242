import React, { useState, useEffect, KeyboardEvent } from 'react';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

type TextInputProps = {
    placeholder: string;
    label?: string;
    value?: string;
    phoneValue?: string;
    mandatory: boolean;
    width: 'half' | 'full' | 'nano';
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPhoneChange?: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    secret?: boolean;
    phone?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({placeholder, label, value, mandatory,
                                                 width, onChange, onPhoneChange,
                                                 onKeyDown, secret, phone }) => {
    const [isMobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const getWidthClass = () => {
        if (isMobileView) {
            switch(width) {
                case 'full':
                    return 'w-formfieldmobilefull';
                case 'nano':
                    return 'w-formfieldmobilenano';
                default: // 'half'
                    return 'w-formfieldmobilehalf';
            }
        } else {
            switch(width) {
                case 'full':
                    return 'w-formfielddesktopfull';
                case 'nano':
                    return 'w-formfielddesktopnano';
                default: // 'half'
                    return 'w-formfielddesktophalf';
            }
        }
    }

    return (
        <div className={`mt-3 ${getWidthClass()}`}>
            {label &&
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    {label}
                    {mandatory && <span className="text-red-500"> *</span>}
                </label>
            }
            <div className="flex items-center space-x-2">
                {phone &&
                    <div className="w-11 flex-shrink-0 -mt-10"> {/* Adjust width as needed */}
                        <ReactPhoneInput
                            country={'ch'}
                            onChange={onPhoneChange}
                            disableDropdown={false}
                            disableCountryCode={true}
                            enableAreaCodes={false}
                            placeholder="" // Removes default placeholder text
                            inputStyle={{ display: 'none' }} // Hide the input field, keeping only the dropdown visible
                            buttonStyle={{
                                width: '100%',
                                borderRadius: '4px',
                                border: '1px solid #d1d5db',
                                height: '38px', // Match the input height
                                cursor: 'pointer',
                                backgroundColor: '#fff',
                                padding: 0
                            }}
                        />
                    </div>
                }
                <input
                    className="shadow appearance-none border rounded w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type={secret ? "password" : "text"}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    onKeyDown={onKeyDown}
                    style={{ height: '38px' }} // Ensure input height matches dropdown
                />
            </div>
        </div>
    );
};

export default TextInput;
