import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import React from 'react';
import SchoolHub from './pages/SchoolHub';
import AuthProvider from "./AuthProvider";
import {BrowserRouter as Router} from "react-router-dom";

// Create Apollo client
const client = new ApolloClient({
    uri: process.env.REACT_APP_SCHOOLHUB_API_ENDPOINT,
    cache: new InMemoryCache()
});

const App: React.FC = () => {
    const width = window.innerWidth;
    if(width <= 1024) return <p>This application does not work with mobile devices for the moment. For a better user experience, please use a computer.</p>

    return (
        <ApolloProvider client={client}>
            <Router>
                <AuthProvider>
                    <SchoolHub />
                </AuthProvider>
            </Router>
        </ApolloProvider>
    );
};

export default App;