import { gql } from "@apollo/client";

export const GET_REGIONS = gql`
	query GetRegions {
		getRegions {
			regionId
			regionName
			country {
				countryId
			}
		}
	}
`;

export const GET_REGIONS_BY_COUNTRY_ID = gql`
    query GetRegionsByCountryId($countryId: String!) {
      getRegionsByCountryId(countryId: $countryId) {
        regionName
        regionId
      }
    }
`;