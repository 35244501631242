type H2Props = {
    text: string;
};

const H2: React.FC<H2Props> = ({text}) => {
    return (
        <h2 className="text-regentblue text-lg font-semibold">{text}</h2>
    );
};

export default H2;