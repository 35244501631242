export const formatDate = (dateString: string) => {
	const options: Intl.DateTimeFormatOptions = {
		weekday: "short",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	return new Date(dateString).toLocaleDateString("en-US", options);
};

export const utf8ToBase64 = (str: string) => {
	return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
		return String.fromCharCode(Number(`0x${p1}`))
	}))
}

export const formatMoney = (moneyString: string | undefined, currency: string): string => {
	// Convert the moneyString to a number
	if (moneyString === undefined) {
		return "";
	}

	const amount = parseFloat(moneyString);

	// Determine the locale and format the amount accordingly
	let formattedAmount;
	switch (currency) {
		case "CHF":
			formattedAmount = amount.toLocaleString('de-CH', { minimumFractionDigits: 2 });
			break;
		case "USD":
			formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			break;
		case "EUR":
			formattedAmount = amount.toLocaleString('de-DE', { minimumFractionDigits: 2 });
			break;
		default:
			throw new Error("Unsupported currency");
	}

	// Append the currency to the string and return it
	return `${formattedAmount} ${currency}`;
};