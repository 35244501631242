import React, { createContext, useState, useEffect, ReactNode, Dispatch } from "react";

type AuthContextType = {
    token: string | null;
    setToken: Dispatch<React.SetStateAction<string | null>>;
};

export const AuthContext = createContext<AuthContextType>({
    token: null,
    setToken: () => {},
});

interface IAuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const localToken = window.localStorage.getItem('authToken');
        if (localToken) {
            setToken(localToken);
        }
    }, []);

    useEffect(() => {
        if (token) {
            window.localStorage.setItem('authToken', token);
        } else {
            window.localStorage.removeItem('authToken');
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;