import React, {useContext, useState} from 'react';
import TextInput from './sub-components/TextInput';
import PrimaryButton from './sub-components/PrimaryButton';
import H2 from "./sub-components/H2";
import {LOGIN} from "../queries/account_queries";
import {useLazyQuery} from "@apollo/client";
import { AuthContext } from '../AuthProvider';
import {useNavigate} from "react-router-dom";

type MiniWindowProps = {
    onLoginSuccess: () => void;
    onRegisterLinkClick: () => void;
};

const LoginWindow: React.FC<MiniWindowProps> = ({ onLoginSuccess, onRegisterLinkClick }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [login, { data: loginData}] = useLazyQuery(LOGIN);

    const navigate = useNavigate();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const auth = useContext(AuthContext);

    const isProduction = process.env.REACT_APP_ENV === 'prod';

    const setAuthCookie = (token: string) => {
        let cookieString = `schoolhubToken=${token};path=/;SameSite=Lax`;

        if (isProduction) {
            // Add `Secure` and `domain` attributes only in production
            cookieString += `;domain=schoolhub.regentschool.ch;Secure`;
        }

        document.cookie = cookieString;
    };

    const handleLoginClick = async () => {
        try {
            const loginRes = await login({ variables: { email: email, password: password } });

            if (loginRes.data.login === 'NOK') {
                setErrorMessage('Incorrect email or password');
            }
            else {
                auth.setToken(loginRes.data.login);
                setAuthCookie(loginRes.data.login);
                onLoginSuccess();
            }
        }
        catch (error:any) {
            console.error('Error occured while logging in:', error.message);
            setErrorMessage('Error occured while logging in: ' + error.message);
        }
    };

    const onPasswordForgottenLinkClick = () => {
        navigate("reset-password");
    }

    const handlePasswordKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            void handleLoginClick();
        }
    };


    return (
        <div className="mini-window bg-white p-4 shadow-lg flex flex-col items-center space-y-4 rounded-4xl">
            <H2 text={"Log in or create an account"}/>
            <TextInput
                placeholder="Email"
                label="Email"
                mandatory={true}
                width="nano"
                onChange={handleEmailChange}
            />
            <TextInput
                placeholder="Password"
                label="Password"
                mandatory={true}
                width="nano"
                secret={true}
                onChange={handlePasswordChange}
                onKeyDown={handlePasswordKeyDown}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <PrimaryButton action={handleLoginClick} width="half" label="Login" />
            <p className="cursor-pointer" onClick={onRegisterLinkClick}>
                No account? <u>Please register.</u>
            </p>
            <p className="cursor-pointer" onClick={onPasswordForgottenLinkClick}>
                Password forgotten? <u>Reset it here.</u>
            </p>
        </div>
    );
};

export default LoginWindow;