import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {jwtDecode} from "jwt-decode";
import Module from "../components/Module";
import campModuleIcon from "../media/icon/module_camp_icon.png";
import campManagerModuleIcon from "../media/icon/module_campmanager_icon.png";
import { AuthContext } from "../AuthProvider";
import { GET_ACCESS_BY_ACCOUNT_ID } from "../queries/access_queries";

const extractIdFromToken = (token: string): string | null => {
    try {
        const decodedToken = jwtDecode<{ id: string }>(token);
        return decodedToken.id;
    } catch {
        return null;
    }
};

const ModuleList: React.FC = () => {
    const { token } = useContext(AuthContext);
    const [accessCodes, setAccessCodes] = useState<string[]>([]);
    const [getAccessByAccountId, { data, loading, error }] = useLazyQuery(GET_ACCESS_BY_ACCOUNT_ID);

    useEffect(() => {
        if (token) {
            const accountId = extractIdFromToken(token);
            if (accountId) {
                getAccessByAccountId({ variables: { accountId } })
                    .then(response => {
                        console.log("Response data:", response.data);
                        console.log(accountId);
                    })
                    .catch(err => console.error("Error executing query:", err));
            }
        }
    }, [token, getAccessByAccountId]);

    useEffect(() => {
        if (data && data.getAccessByAccountId) {
            setAccessCodes(data.getAccessByAccountId.map((access: { accessCode: string }) => access.accessCode));
        }
    }, [data]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading access codes.</p>;

    // Determine which modules to display based on access codes
    const modules = [
        accessCodes.includes("CAMP_APP") && (
            <Module
                key="camp_app"
                icon={campModuleIcon}
                iconName="Régent Camps"
                url={process.env.REACT_APP_CAMP_APP_URL || ""}
            />
        ),
        accessCodes.includes("CAMP_MANAGER") && (
            <Module
                key="camp_manager"
                icon={campManagerModuleIcon}
                iconName="Camp Manager"
                url={process.env.REACT_APP_CAMPMANAGER_APP_URL || ""}
            />
        ),
    ].filter(Boolean); // Remove any falsy items

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-xl text-regentyolk font-semibold underline mt-5">
                List of available SchoolHub Modules
            </h1>
            <br />
            <div
                className={`grid gap-4 mt-4 ${
                    modules.length === 1 ? "grid-cols-1" : "grid-cols-2"
                }`}
            >
                {modules}
            </div>
        </div>
    );
};

export default ModuleList;
