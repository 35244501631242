type H1Props = {
    text: string;
};

const H1: React.FC<H1Props> = ({text}) => {
    return (
        <h2 className="text-regentyolk text-2xl font-semibold">{text}</h2>
    );
};

export default H1;