import { gql } from "@apollo/client";

export const ADD_ADDRESS = gql`
mutation AddAddress($addressName: String!, $addressNumber: String!, $streetLine1: String!, $streetLine2: String!, $city: String!,
$zipCode: String!, $countryId: UUID!) {
  addAddress(
    addressInput: {streetLine1: $streetLine1, streetLine2: $streetLine2, addressName: $addressName, addressNumber: $addressNumber, zipCode: $zipCode, city: $city, countryId: $countryId}
  ) {
    message
    address {
        addressId
    }
  }
}`;

export const GET_ADDRESSES_BY_ACCOUNT_ID = gql`
	query GetAddressesByAccountId($accountId: String!) {
		getAddressesByAccountId(accountId: $accountId) {
			addressId
			addressName
			addressStreet
			addressNumber
		}
	}
`;