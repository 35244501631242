import { useState, useEffect, KeyboardEvent, useRef } from "react";

type Option = { id: string | number; value: string } | string;

type DropdownProps = {
    name: string;
    label?: string;
    options: Option[];
    mandatory: boolean;
    width: "half" | "full" | "nano" | "ultranano";
    value?: string;
    onChange?: (value: {
        name: string;
        value: string;
        id: string | number | undefined;
    }) => void;
    active?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
                                               label,
                                               options,
                                               mandatory,
                                               width,
                                               value,
                                               onChange,
                                               name,
                                               active = true,
                                           }) => {
    const [selectedOption, setSelectedOption] = useState<string>(value || "");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>(value || "");

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Filter options based on search term (only filter when user starts typing)
    const filteredOptions = options.filter((option) => {
        const optionValue = typeof option === "string" ? option : option.value;
        return searchTerm === "" || optionValue.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleSelect = (option: Option) => {
        if (!active) return;  // Only proceed if the dropdown is active

        const value = typeof option === "string" ? option : option.value;

        // Set the selected option
        setSelectedOption(value);
        setInputValue(value);  // Update the input to reflect the selected option
        setIsOpen(false);  // Close the dropdown after selecting an option

        if (onChange && name) {
            onChange({
                name: name,
                value: value,
                id: typeof option === "string" ? undefined : option.id?.toString(),
            });
        }
    };



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setSearchTerm(e.target.value);
    };

    const handleFocusOrClick = () => {
        setIsOpen(true);
        setInputValue("");  // Clear the input on focus or click
        setSearchTerm("");  // Show all options initially
        inputRef.current?.focus(); // Focus on the <input> so user can start searching immediately
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && filteredOptions.length > 0) {
            e.preventDefault();
            handleSelect(filteredOptions[0]);  // Pass only the option, not the event
            setIsOpen(false);
            inputRef.current?.blur();
        }
    };


    // If the dropdown loses focus, check if there's a match; if not, revert to the previously selected option
    const handleBlur = () => {
        // Check if the inputValue matches any of the filtered options
        const matchedOption = filteredOptions.find((option) => {
            const optionValue = typeof option === "string" ? option : option.value;
            return optionValue.toLowerCase() === inputValue.toLowerCase();
        });

        // If there is a match, set it as the selected option
        if (matchedOption) {
            const value = typeof matchedOption === "string" ? matchedOption : matchedOption.value;
            setSelectedOption(value);
        } else {
            // If no match, revert to the previously selected option
            setInputValue(selectedOption);
        }

        setIsOpen(false); // Close the dropdown
    };


    useOnClickOutside(dropdownRef, handleBlur);  // Close dropdown and revert on click outside

    useEffect(() => {
        setSelectedOption(value || "");
    }, [value]);

    useEffect(() => {
        if (!isOpen) {
            setInputValue(selectedOption); // Restore selected option when closed
        }
    }, [isOpen, selectedOption]);

    return (
        <div ref={dropdownRef} style={{ position: "relative", overflow: "visible" }}>
            {label && (
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    {label}
                    {mandatory && <span className="text-red-500"> *</span>}
                </label>
            )}
            <div
                onClick={handleFocusOrClick} // Open dropdown and show all options
                className={`block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer relative ${!active ? "opacity-50 cursor-default" : ""}`}
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleFocusOrClick} // Show all options on focus
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}  // Handle blur event to reset the input if needed
                    className="w-full px-2 py-1"
                    placeholder="Select an option"
                    ref={inputRef}
                />
                <div
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                    className="absolute right-3 text-gray-700 pointer-events-none"
                >
                    ▼
                </div>

                {isOpen && (
                    <div
                        className="absolute left-0 w-full mt-2 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-y-auto max-h-60"
                    >
                        {filteredOptions.map((option, index) => {
                            const value = typeof option === "string" ? option : option.value;
                            return (
                                <div
                                    key={index}
                                    onMouseDown={() => handleSelect(option)}  // Call handleSelect directly without passing the event
                                    className="text-gray-700 block px-4 py-2 text-sm border-t border-gray-100 hover:bg-gray-100"
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

// Helper to close dropdown if clicked outside
const useOnClickOutside = (ref: React.RefObject<HTMLDivElement>, handler: () => void) => {
    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }
            handler();
        };
        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [ref, handler]);
};

export default Dropdown;
