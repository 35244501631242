import React, { useState, useEffect, useContext } from 'react';
import '../index.css';
import Menu from "../components/Menu";
import Banner from "../components/Banner";
import ModuleList from '../pages/ModuleList';
import CreateAccount from '../pages/CreateAccount';
import headerImage from '../media/photo/SchoolHub_CampManager_Banner.png';
import ProfilePage from "../pages/ProfilePage";
import Home from "../pages/Home";
import { AuthContext } from '../AuthProvider';
import Body from "../components/Body"; // update to your file path

const SchoolHub: React.FC = () => {
    const { token } = useContext(AuthContext);
    const [content, setContent]
        = useState<'moduleList' | 'createAccount' | 'profilePage' | 'home'>('home');

    const redirectToHome = () => {
        setContent('home');
    };

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <section>
                <Menu />
            </section>
            <div className="flex-grow overflow-auto">
                <section>
                    <Banner />
                    <Body />
                </section>
            </div>
            <footer style={{height: '40px'}} className="bg-white"></footer>
        </div>
    );
};

export default SchoolHub;