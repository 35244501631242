import React from 'react';


type BannerProps = {
    image?: string;
    title?: string;
};

const Banner: React.FC<BannerProps> = ({ image, title }) => {
    if (!image) {
        return null;
    }

    return (
        <div className="w-full h-32 overflow-hidden relative flex items-center justify-center">
            <img src={image}
                 alt="School Hub - Camp Manager - Banner"
                 className="w-full h-full object-top object-cover absolute z-0"/>

            <p className="z-10 text-white font-bold text-5xl font-sans text-shadow-lg">
                {title}
            </p>
        </div>
    );
}

export default Banner;