import React from 'react';

interface ModuleProps {
    icon: string;
    iconName: string;
    url: string;
}

const Module: React.FC<ModuleProps> = ({ icon, iconName, url }) => {
    const handleClick = () => {
        window.open(url, '_blank');
    };

    return (
        <button
            onClick={handleClick}
            className="w-72 h-24 bg-gray-300 border-black border flex items-center justify-center flex-col cursor-pointer
            hover:bg-regentyolk hover:text-white"
        >
            <img src={icon} alt={iconName} className="w-12 h-12" />
            <span>{iconName}</span>
        </button>
    );
};

export default Module;