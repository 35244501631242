import TextInput from "../components/sub-components/TextInput";
import H2 from "../components/sub-components/H2";
import H1 from "../components/sub-components/H1";
import Dropdown from "../components/sub-components/Dropdown";
import PrimaryButton from "../components/sub-components/PrimaryButton";
import Checkbox from "../components/sub-components/Checkbox";
import React, {useState} from "react";
import AddressForm from "../components/AddressForm";
import {useMutation, useLazyQuery} from "@apollo/client";
import { ADD_ACCOUNT } from '../queries/account_queries';
import { ADD_ADDRESS } from '../queries/address_queries';
import { GET_COUNTRY_ID } from '../queries/country_queries';
import { useLocation } from "react-router-dom";
import { LOGIN } from "../queries/account_queries"; // Import the LOGIN query
import {useContext} from "react";
import {AuthContext} from "../AuthProvider";
import Loader from "../components/sub-components/Loader";
import {SEND_EMAIL_RIGHT_NOW} from "../queries/emailworker/email_queue_queries";
import {EmailWorkerClient} from "../context/EmailWorkerClient";
import {utf8ToBase64} from "../helpers/formatting";

type CreateAccountProps = {
    onRegisterSuccess: (redirectUrl: string) => void;
};

type Address = {
    companyName?: string;
    street1?: string;
    street2?: string;
    city?: string;
    region?: string;
    zip?: string;
    country?: string;
};

const CreateAccount: React.FC<CreateAccountProps> = (props) => {
    // new state for controlling display of extra address fields
    const [domicileAddress, setDomicileAddress] = useState<Address>({});
    const [billingAddress, setBillingAddress] = useState<Address>({});
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    // Parse the query string to get the redirect URL
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect') || '/modules';

    const auth = useContext(AuthContext);
    const [login, { data: loginData }] = useLazyQuery(LOGIN);

    const isProduction = process.env.REACT_APP_ENV === 'prod';

    const setAuthCookie = (token: string) => {
        let cookieString = `schoolhubToken=${token};path=/;SameSite=Lax`;

        if (isProduction) {
            // Add `Secure` and `domain` attributes only in production
            cookieString += `;domain=schoolhub.regentschool.ch;Secure`;
        }

        document.cookie = cookieString;
    };



    // Reusable login function
    const loginAfterRegister = async () => {
        try {
            const loginRes = await login({ variables: { email, password } });

            if (loginRes.data.login === 'NOK') {
                alert('Login failed after registration.');
            } else {
                // Set the token in AuthContext and redirect
                auth.setToken(loginRes.data.login);

                // Store the token as a cookie
                setAuthCookie(loginRes.data.login);
                setIsLoading(false);
                props.onRegisterSuccess(redirectUrl); // Navigate to the correct URL after login
            }
        } catch (error: any) {
            console.error('Error occurred during login:', error.message);
            alert('Error occurred during automatic login: ' + error.message);
        }
    };

    const handleAddressChange = (addressType: string, values: any) => {
        if (addressType === 'domicile') {
            setDomicileAddress(values);
        } else if (addressType === 'billing') {
            setBillingAddress(values);
        }
    }

    const [displayExtraAddressFields, setDisplayExtraAddressFields] = useState(false);
    const toggleDisplayExtraAddressFields = (value: boolean) => setDisplayExtraAddressFields(value);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [role, setRole] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [phoneExtension, setPhoneExtension] = useState('+41');


    const [addAccount] = useMutation(ADD_ACCOUNT);
    const [addAddress] = useMutation(ADD_ADDRESS);
    const [getCountryId, { data: countryData }] = useLazyQuery(GET_COUNTRY_ID);
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handlePhoneExtensionChange = (value: string) => {
        setPhoneExtension(`+${value}`);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const validatePasswords = () => {
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return false;
        }

        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);

        if (!hasLetter || !hasNumber) {
            setErrorMessage("Password must contain at least one letter and one number.");
            return false;
        }

        setErrorMessage(''); // Clear the error message if everything is valid
        return true;
    };

    const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstname(event.target.value);
    }

    const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastname(event.target.value);
    }

    const handlePhonenumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhonenumber(event.target.value);
    }

    const register = async () => {
        setIsLoading(true);
        // Check for mandatory fields
        if (!email || !password || !firstname || !lastname || !phonenumber ||
            !domicileAddress.street1 || !domicileAddress.city || !domicileAddress.region ||
            !domicileAddress.zip || !domicileAddress.country) {
            setErrorMessage("Please fill in all the mandatory fields");
            setIsLoading(false);
            return;
        }

        if (!validatePasswords()) {
            setIsLoading(false);
            return;
        }

        try {
            // Fetch country ID and role ID
            const domicileCountryIdPromise = getCountryId({ variables: { countryName: domicileAddress.country } });
            const domicileCountryRes = await domicileCountryIdPromise;

            // Extract IDs
            const domicileCountryId = domicileCountryRes.data.getCountryIdByName;

            if(!domicileCountryId) {
                alert("Could not fetch country or role.");
                return;
            }

            // Create an address
            const domicileAddressRes = await addAddress({
                variables: {
                    addressName: firstname + ' ' + lastname,
                    addressNumber: "2",
                    city: domicileAddress.city,
                    countryId: domicileCountryId,
                    streetLine1: domicileAddress.street1,
                    streetLine2: domicileAddress.street2,
                    zipCode: domicileAddress.zip,
                    companyName: domicileAddress.companyName
                }
            });
            const domicileAddressId = domicileAddressRes.data.addAddress.address.addressId;
            let billingAddressId = domicileAddressId;

            if(displayExtraAddressFields) {
                // Fetch country ID
                const billingCountryIdPromise = getCountryId({ variables: { countryName: billingAddress.country } });
                const billingCountryRes = await billingCountryIdPromise;

                // Extract ID
                const billingCountryId = billingCountryRes.data.getCountryIdByName;

                if(!billingCountryId) {
                    alert("Could not fetch country.");
                    return;
                }

                // Create an address
                const billingAddressRes = await addAddress({
                    variables: {
                        addressName: firstname + ' ' + lastname,
                        addressNumber: "2",
                        city: billingAddress.city,
                        countryId: billingCountryId,
                        streetLine1: billingAddress.street1,
                        streetLine2: billingAddress.street2,
                        zipCode: billingAddress.zip,
                        companyName: billingAddress.companyName
                    }
                });

                billingAddressId = billingAddressRes.data.addAddress.address.addressId;
            }


            // Create an account
            const accountRes = await addAccount({
                variables: {
                    email: email,
                    password: password,
                    phone: phonenumber,
                    phoneExtension: phoneExtension,
                    firstname: firstname,
                    lastname: lastname,
                    domicileAddressId: domicileAddressId,
                    billingAddressId: billingAddressId,
                    roleId: '48a13c37-e09e-4680-8082-ded3f238c28d' // This is the role "User" by default
                }
            });

            if (!accountRes || !accountRes.data.addAccount) {
                alert('No message returned from addAccount mutation');
                return;
            }

            if(accountRes.data.addAccount.errorCode === "409") {
                // Conflict, account already exists
                setErrorMessage(accountRes.data.addAccount.message);
                setIsLoading(false);
                return;
            }
            else {
                // AddAccount was successful
                await sendConfirmationEmail(accountRes.data.addAccount.account.accountId);
                await loginAfterRegister();
            }
        }
        catch (error: unknown) {
            if (error instanceof Error) { // we check that error is an instance of Error
                console.error('Error encountered:', error);
                alert(`An error occurred: ${error.message}`);
            } else {
                // error is some other type - either we can re-throw it, or just log it as is, as doing so will not cause a TS error
                console.error('Some other error: ', error);
            }
        }
    };

    // rendering the extra address fields if the checkbox is checked
    const renderExtraAddressFields = () => {
        if (!displayExtraAddressFields) return null;

        return (
            <section className="mt-8">
                <H2 text={"Billing Address"}/>
                <AddressForm addressType={"billing"} onAddressChange={handleAddressChange} />
            </section>
        );
    }

    const [sendEmailRightNow, { data, loading, error }] = useMutation(SEND_EMAIL_RIGHT_NOW, {
        client: EmailWorkerClient,
    });

    const sendConfirmationEmail = async (accountId: string) => {
        const emailQueueInput = {
            input: {
                emailTo: [email],
                emailCc: [""],
                emailBcc: [""],
                serviceCode: "regentcamps",
                tableToCheck: "application",
                templateCode: "schoolhub_account_created",
                variables: utf8ToBase64(JSON.stringify({
                    account_firstname: firstname,
                    account_lastname: lastname,
                })),
                timeToSend: new Date(new Date().getTime() + 600000).toISOString(),
                accountId: accountId,
            },
        };

        try {
            const response = await sendEmailRightNow({ variables: emailQueueInput });
        } catch (e) {
            console.error("Error sending email:", e);
        }
    };

    return (
        <div className="w-3/4 h-96 mx-auto">
            {/* Page main info */}
            <section className="mt-5">
                <H1 text={"CREATE AN ACCOUNT"} />
                <p className="mt-5">
                    You’re about to create an account on School Hub, an application created by Le Régent International School.
                </p>
                <p>
                    Your School Hub account will enable you to access Régent Applications and will assist in helping you manage life at Camp or at the School.
                </p>
            </section>

            {/* Account Details */}
            <section className="mt-8">
                <H2 text={"Account Details"}/>
                <TextInput placeholder={"james.smith@email.com"} label={"Email"}
                           mandatory={true} width={"half"} onChange={handleEmailChange}/>
                <div className="flex justify-between">
                    <TextInput placeholder={""} label={"Choose a secure password"} mandatory={true} width={"half"}
                               secret={true} onChange={handlePasswordChange}/>
                    <TextInput placeholder={""} label={"Confirm password"} secret={true} mandatory={true} width={"half"}
                               onChange={handleConfirmPasswordChange}/>
                </div>
            </section>

            {/* Contact Information */}
            <section className="mt-8 mb-12">
                <H2 text={"Contact Information"}/>
                <div className="flex justify-between">
                    <TextInput placeholder={"James"} label={"First Name (on passport)"}
                               mandatory={true} width={"half"} onChange={handleFirstnameChange}/>
                    <TextInput placeholder={"Smith"} label={"Last Name (on passport)"}
                               mandatory={true} width={"half"} onChange={handleLastnameChange}/>
                </div>
                <div className="flex items-center">
                    <div className="flex-1"> {/* Flex container for the phone number input */}
                        <TextInput
                            placeholder={"Phone Number"}
                            label={"Phone Number"}
                            mandatory={true}
                            width={"half"}
                            onChange={handlePhonenumberChange}
                            onPhoneChange={handlePhoneExtensionChange}
                            phone={true}
                        />
                    </div>
                </div>
            </section>

            {/* Address */}
            <section className="mt-8">
                <H2 text={"Address"}/>
                <AddressForm addressType={"domicile"} onAddressChange={handleAddressChange}/>
            </section>

            <section className="mt-8">
                <Checkbox label="Different Billing Address" onChange={toggleDisplayExtraAddressFields}/>
            </section>
            {/* Extra address fields */}

            {renderExtraAddressFields()}

            {/* Register */}
            <section className="mt-8">
                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                {isLoading && (
                    <div
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2
                        -translate-y-1/2 w-screen h-screen flex items-center justify-center z-50">
                        <Loader text="Creating your account..."/>
                    </div>
                )}
                <PrimaryButton
                    action={register}
                    width="half"
                    label="Register"
                />
            </section>
        </div>
    );
}

export default CreateAccount;