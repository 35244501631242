import React, {useContext, useEffect, useState} from 'react';
import TextInput from "./sub-components/TextInput";
import PrimaryButton from "./sub-components/PrimaryButton";
import {RESET_PASSWORD} from "../queries/account_queries";
import {LOGIN} from "../queries/account_queries";
import {useLazyQuery, useMutation} from "@apollo/client";
import {AuthContext} from "../AuthProvider";
import {jwtDecode} from "jwt-decode";

type DecodedToken = {
    email: string;
    fullname: string;
    id: string;
}

const ResetPasswordCard: React.FC = () => {
    const auth = useContext(AuthContext);
    let id = "";
    let email = "";

    if(auth.token) {
        const decodedToken = jwtDecode<DecodedToken>(auth.token);
        id = decodedToken.id;
        email = decodedToken.email;
    }

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [message, setMessage] = useState<String>("")

    const [resetPassword] = useMutation(RESET_PASSWORD);
    const [login, { data, called, loading }] = useLazyQuery(LOGIN, { fetchPolicy: "network-only" });

    const handleResetPassword = async () => {
        setMessage("");

        if (newPassword !== confirmNewPassword) {
            setMessage("Passwords do not match");
            return;
        }

        const loginRes = await login(
            { variables: {
                email,
                    password: currentPassword } });

        console.log("Login function was called");

        if (loginRes.data.login == "NOK") {
            setMessage("Incorrect password provided.");
            return;
        }

        if (currentPassword == newPassword) {
            setMessage("The new password is the same as the current password.");
            return;
        }

        if (called && !loading && data) {
            if (data.login) {
                resetPassword({
                    variables: {
                        newPassword,
                        accountId: id
                    }
                }).then((result) => {
                    if (result.data?.resetPassword?.message) {
                        setMessage("Password reset successful");
                    }
                }).catch(error => {
                    // handle the error here
                    console.error(error);
                });
            } else {
                setMessage("Incorrect password provided");
            }
        }
    };

    return (
        <div className="border p-8 rounded-lg shadow max-w-lg mx-auto">
            <div className="flex justify-between items-center">
                <h2 className="font-bold text-lg">Reset Password</h2>
            </div>
            <div className="mt-4">
                <TextInput
                    label="Current Password"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(event) => setCurrentPassword(event.target.value)}
                    mandatory={true}
                    secret={true}
                    width="nano"
                />
                <TextInput
                    label="New Password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    mandatory={true}
                    secret={true}
                    width="nano"
                />
                <TextInput
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(event) => setConfirmNewPassword(event.target.value)}
                    mandatory={true}
                    secret={true}
                    width="nano"
                />
            </div>
            <div className="mt-8">
                <p className={"message text-center mb-3 " + (message === "Password reset successful" ? "text-green-500" : "text-red-500")}>{message}</p>
                <PrimaryButton
                    label="Reset Password"
                    action={handleResetPassword}
                    width="nano"
                />
            </div>
        </div>
    );
};

export default ResetPasswordCard;