import { AuthContext } from '../AuthProvider';
import { useContext, useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import InformationCard from "../components/InformationCard";
import ResetPasswordCard from "../components/ResetPasswordCard";
import Banner from "../components/Banner";
import ProfileBanner from "../media/photo/SchoolHub_Profile_Banner.png";
import SecondaryButton from "../components/sub-components/SecondaryButton";
import { GET_ACCOUNT_BY_ID } from "../queries/account_queries";
import { useLazyQuery } from "@apollo/client";

type ProfilePageProps = {
    onLogOut: () => void;
};

type DecodedToken = {
    email: string;
    fullname: string;
    id: string;
};

const ProfilePage: React.FC<ProfilePageProps> = (props) => {

    const auth = useContext(AuthContext);
    let id = "";

    if (auth.token) {
        const decodedToken = jwtDecode<DecodedToken>(auth.token);
        id = decodedToken.id;
    }

    const [accountInfo, setAccountInfo] = useState<any>(null);
    const [showNotLoggedInMessage, setShowNotLoggedInMessage] = useState(false);
    const [getAccountById, { data, loading }] = useLazyQuery(GET_ACCOUNT_BY_ID, { fetchPolicy: "network-only" });

    useEffect(() => {
        if (id) {
            getAccountById({ variables: { accountId: id } });
        }
    }, [id, getAccountById]);

    useEffect(() => {
        if (data) {
            if (data.getAccountById) {
                setAccountInfo(data.getAccountById);
            } else {
                // Account not found
                setShowNotLoggedInMessage(true);
            }
        }
    }, [data]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!id) {
                setShowNotLoggedInMessage(true);
            }
        }, 2000);

        return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }, [id]);

    const logOut = () => {
        props.onLogOut();
    };

    if (loading) {
        return <>Loading...</>;
    }

    if (showNotLoggedInMessage || !accountInfo) {
        return (
            <p>
                Not logged in. <a href="/login">Click here to log in</a>
            </p>);
    }

    return (
        <>
            <Banner image={ProfileBanner} title="PROFILE"/>
            <div className="flex p-8 justify-center">
                <>
                    <InformationCard
                        title="Personal Information"
                        infoFields={[
                            {title: 'Email address', value: accountInfo?.email},
                            {title: 'Phone number', value: accountInfo?.phone},
                            {title: 'First name', value: accountInfo?.firstname},
                            {title: 'Last name', value: accountInfo?.lastname},
                            {title: 'Address Line 1', value: accountInfo?.domicileAddress.streetLine1},
                            {title: 'Address Line 2', value: accountInfo?.domicileAddress.streetLine2},
                            {title: 'ZIP', value: accountInfo?.domicileAddress.zipCode},
                            {title: 'City', value: accountInfo?.domicileAddress.city},
                            {title: 'Country', value: accountInfo?.domicileAddress.country.countryName},
                        ]}
                        refetchAccount={() => getAccountById({variables: {accountId: id}})}
                        accountInfo={accountInfo}
                    />
                </>
                <div className="ml-8">
                    <ResetPasswordCard passwordForgotten={false}/>
                </div>
            </div>

            <div className="flex justify-center">
                <SecondaryButton label="Log out" action={logOut} width="half"/>
            </div>
        </>
    );
};

export default ProfilePage;
