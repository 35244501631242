import { gql } from "@apollo/client";

export const GET_COUNTRY_ID = gql`
    query GetCountry($countryName: String!) {
      getCountryIdByName(countryName: $countryName)
    }
`;

export const GET_COUNTRIES = gql`
    query GetCountries {
      getCountries {
        countryId
        countryName
      }
    }
`;